import ErrorBoundary from '@im-frontend/components/ErrorBoundary';
import { mustReportException } from '@im-frontend/utils/errors';
import {
  canIgnoreBreadCrumb,
  sanitizeEvent,
} from '@im-frontend/utils/sentryHelpers';
import * as Sentry from '@sentry/react';
import App from 'components/App';
import { environment } from 'namespaceInfo';
import React from 'react';
import ReactDOM from 'react-dom';

if (environment !== 'ci') {
  Sentry.init({
    dsn: 'https://bf131930c26044d7a43f017674463190@o37119.ingest.sentry.io/5432561',
    release: document.getElementById('root')?.dataset?.release,
    beforeSend(event) {
      sanitizeEvent(event);
      return event;
    },
    beforeBreadcrumb: function (breadcrumb, hint) {
      if (canIgnoreBreadCrumb(breadcrumb, hint)) {
        return null;
      }
      return breadcrumb;
    },
  });
}

const onError = (error: any, errorInfo: any) =>
  mustReportException(error, {
    extra: errorInfo,
    tags: { fullPageCrash: true },
  });

const renderApp = () => {
  ReactDOM.render(
    <React.StrictMode>
      <ErrorBoundary onError={onError}>
        <App />
      </ErrorBoundary>
    </React.StrictMode>,
    document.getElementById('root')
  );
};

if (window.Office) {
  window.Office.onReady(() => {
    renderApp();
  });
} else {
  renderApp();
}
