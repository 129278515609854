import { Button } from '@appfolio/react-gears';
import * as api from 'api';
import Placard from 'components/shared/Placard';
import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';

type SessionInfo = api.v0.UserInfo | null | undefined;

/**
 * Quasi-useless page that we serve up as the default route, just so there's something to see.
 */
function Dashboard({ history }: RouteComponentProps) {
  const [userInfo, setUserInfo] = useState<SessionInfo>();

  useEffect(() => {
    if (userInfo === undefined) {
      api.v0.oauth2.userInfo().then(setUserInfo);
    }
  }, [userInfo]);

  let message: string | React.ReactNode = 'Checking session status...';
  let actions: React.ReactNode;

  const signIn = () => history.push('/session/new');
  const signOut = () =>
    api.v0.oauth2.revoke().then(() => history.push('/session/new'));
  const selectAccount = () => {
    if (userInfo.internal_user) {
      history.push('/appf');
    } else {
      history.push(`/principals/${userInfo.id}`);
    }
  };

  if (userInfo) {
    message = `Please select an account or sign out.`;
    actions = (
      <>
        <Button color="primary" onClick={selectAccount}>
          Select Account
        </Button>
        <Button color="link" onClick={signOut}>
          Sign Out
        </Button>
      </>
    );
  } else if (userInfo === null) {
    if (window.Excel) {
      message = (
        <>
          <p style={{ textAlign: 'left' }}>
            This is an add-in to import reports from AppFolio Investment Manager
            into Excel. Please sign in with your AppFolio account.
          </p>
          <p style={{ textAlign: 'left' }}>
            Don't have an account?{' '}
            <a href="https://www.appfolio.com/investment-manager/free-demo">
              Sign Up
            </a>
          </p>
        </>
      );
    } else {
      message = 'You are not signed in.';
    }
    actions = (
      <>
        <Button color="primary" onClick={signIn}>
          Sign In
        </Button>
        <Button color="link" onClick={() => history.goBack()}>
          Back
        </Button>
      </>
    );
  }

  return (
    <Placard>
      <p style={{ maxWidth: '396px' }}>{message}</p>
      <div>{actions}</div>
    </Placard>
  );
}

export default Dashboard;
