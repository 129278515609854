import {
  CheckboxInput,
  Container,
  FormLabelGroup,
  Select,
} from '@appfolio/react-gears';
import * as cypress from '@im-frontend/utils/cypress';
import * as api from 'api';
import Placard from 'components/shared/Placard';
import React, { useEffect, useState } from 'react';

// Compensate for missing service_uri for older accounts.
const serviceURI = (account: api.v1.Account) =>
  account.service_uri ||
  (window.location.hostname.includes('.qa.')
    ? `https://app.qa.appfolioim.net/${account.name}`
    : `https://app.appfolioim.com/${account.name}`);

function VisitAccount() {
  const [accounts, setAccounts] = useState<api.v1.Account[] | undefined>();
  const [lastVisit, setLastVisit] = useState<api.v1.Account | undefined>();

  const [visitZenGarden, setVisitZenGarden] = useState(false);

  useEffect(() => {
    if (!accounts) {
      api.v1.accounts.list('im').then(setAccounts);
    }
  }, [accounts]);

  const loadOptions = (prefix: string, callback: (x: null, y: any) => void) => {
    if (!prefix.length || !accounts) {
      callback(null, { complete: false, options: [] });
      return;
    }

    const matches = accounts.filter(a => a.name.includes(prefix));
    const options = matches.map(account => ({
      id: serviceURI(account),
      label: account.name,
    }));
    callback(null, { complete: true, options });
  };

  const visitAccount = (option: any) => {
    if (option) {
      const serviceURI = option.id;
      setLastVisit(option.id);
      const tabName = cypress.isPresent
        ? undefined
        : serviceURI.replace(/[^A-Za-z0-9]+/g, '-');
      const landingPage = visitZenGarden ? 'appf' : 'login';
      if (window.Excel) {
        const url = `${serviceURI}/login?appf=1`;
        window.location.href = url; // Excel add-in needs to open in the same tab
      } else {
        const url = `${serviceURI}/${landingPage}?appf=1`;
        window.open(url, tabName);
      }
    }
  };

  return (
    <Placard>
      <FormLabelGroup label="Account Name">
        <Select
          loadOptions={loadOptions}
          onChange={visitAccount}
          value={lastVisit}
        />
      </FormLabelGroup>
      <FormLabelGroup label="Options for Visit">
        <Container>
          <CheckboxInput
            type="checkbox"
            checkboxLabel="Go to Admin Settings"
            checked={visitZenGarden}
            onChange={setVisitZenGarden}
          />
        </Container>
      </FormLabelGroup>
    </Placard>
  );
}

export default VisitAccount;
